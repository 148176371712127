/** @format */

import { createRef, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FlipIcon from '@mui/icons-material/Flip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGroupAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useGroupAPI';
import GroupItem from '../GroupItem/GroupItem';
import Product from 'pages/PhotoAdmin/AdminMenu/Groups/Group/Product/Product';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  Transition,
  useStyles,
  BackgroundContainer,
  HomeWrapper,
  BackgroundImage,
  TitleContainer,
  Title,
  ProductsWrapper,
  GroupsWrapper,
  BugWrapper,
  BreadcrumbWrapper,
  MultipleControls,
  UpdateIndicator,
  ButtonWrapper
} from './styles';
import Breadcrumb from 'components/PhotoAdminComponents/Group/Breadcrumb/Breadcrumb';
import { CircularProgress, Container, TablePagination, Typography } from '@material-ui/core';
import NewGroupDialog from 'components/PhotoAdminComponents/Group/Dialogs/NewGroupDialog/NewGroupDialog';
import EditGroupDialog from 'components/PhotoAdminComponents/Group/Dialogs/EditGroupDialog/EditGroupDialog';
import DeleteDialog from 'components/PhotoAdminComponents/Group/Dialogs/DeleteCoverDialog/DeleteDialog';
import { useGetQueryString } from 'hooks/useGetQueryString';
import { LoadingWrapper } from './styles';
import Uploader from 'components/PhotoAdminComponents/Group/Uploader/Uploader';
import Results from 'components/PhotoAdminComponents/Group/Uploader/Results/Results';
import { clearUploads } from 'redux/slices/UploadSlice';
import { useProductAPI } from './Product/hooks/useProductAPI';
import { getApi } from 'api/axios';
import { useRef } from 'react';
import AddGroupCard from 'components/PhotoAdminComponents/Group/AddGroupCard/AddGroupCard';
import { useBatchLogic } from './Product/hooks/useBatchLogic';
import { useModifierApi } from 'pages/PhotoAdmin/AdminMenu/Templates/hooks/useModifierApi';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import ImageUploader from 'components/common/ImageUploader/ImageUploader';

const Group = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { id } = useParams();

  const [galleryId, setGalleryId] = useState(id);
  const dispatch = useDispatch();

  const getUriId = (segment) => {
    return +segment.match('mod#id:[0-9]*')[0]?.split(':')[1];
  };
  const [disabled, setDisabled] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);

  useEffect(() => {
    setGalleryId(id);
    dispatch(clearUploads());
  }, [id]);

  const { modifiers, getModifiers } = useModifierApi();

  const baseUrl = window._env_.REACT_APP_QDAK_PRODUCT_URL;

  const uploadLoading = useSelector((state) => state.nonPersistedReducers.uploadSliceReducer.loading);
  const shopId = useSelector((state) => state.nonPersistedReducers.shopSliceReducer.shopId);

  const [createModal, setCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setCreateModal(!createModal);
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [selected, setSelected] = useState(false);

  const deleteModalRef = createRef(null);
  const deleteProdModalRef = createRef(null);

  const handleDelete = async () => {
    let body = { ...header, coverPicture: null };
    delete body?.modifiers;
    delete body?.modifierTemplate;
    putHeader({ id: galleryId, body });
    setCover(null);
    setConfirmOpen(false);
  };

  const {
    fetchHeader,
    putHeader,
    header,
    parentHeader,
    fetchParentHeader,
    fetchCover,
    coverLoading,
    setCoverLoading,
    cover,
    setCover,
    fetchProducts,
    fetchAllProducts,
    allProducts,
    products,
    setProducts,
    productsLength,
    fetchInnerGroups,
    innerGroupLoading,
    innerGroups,
    setInnerGroups,
    loading,
    putGroup,
    deleteGroup,
    groupDeleting,
    fetchAllGroups,
    allGroupsLength
  } = useGroupAPI();

  useEffect(() => {
    if (user?.ownedShops?.find((shop) => shop.id == shopId)?.isDemo && allGroupsLength >= 6) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [allGroupsLength, user]);
  useEffect(() => {
    if (user?.ownedShops?.find((shop) => shop.id == shopId)?.isDemo && allProducts?.length >= 6) {
      setUploadDisabled(true);
    } else {
      setUploadDisabled(false);
    }
  }, [allProducts, user]);

  const { deleteProduct, putProduct, productDeleting, productEnabling } = useProductAPI();

  const { currentPage, setCurrentPage, pageSize, setPageSize, getQueryString, getProductQueryString } =
    useGetQueryString();

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const [confirmProdOpen, setConfirmProdOpen] = useState(false);

  const routeChange = () => {
    navigate(`/shops/${shopId}/administration/galleries`);
  };

  const queryParams = [
    {
      name: 'deliveryTarget',
      value: encodeURI(
        `deliveryTarget=${header?.belongsTo ? `${header?.belongsTo?.name} - ${header?.name}` : header?.name}`
      )
    },
    // {
    //   name: "createdAt",
    //   value: `createdAtFromDateTime=${getDateString(
    //     header?.createdAt
    //   )}&createdAtToDateTime=${getDateString(new Date())}`,
    // },
    {
      name: 'size',
      value: `size=${100000}`
    },
    {
      name: 'page',
      value: `page=1`
    }
  ];
  const queryParams2 = [
    {
      name: 'deliveryTarget',
      value: encodeURI(
        `deliveryTarget=${header?.belongsTo ? `${header?.belongsTo?.name} - ${header?.name}` : header?.name}`
      )
    },
    // {
    //   name: "createdAt",
    //   value: `createdAtFromDateTime=${getDateString(
    //     header?.createdAt
    //   )}&createdAtToDateTime=${getDateString(new Date())}`,
    // },
    {
      name: 'size',
      value: `size=${100000}`
    },
    {
      name: 'page',
      value: `page=1`
    }
  ];

  const [pdfLoading, setPdfLoading] = useState(false);
  const handleGotoOrders = () => {
    navigate(`/shops/${shopId}/administration/orders/${getQueryString(queryParams2)}`);
  };
  const handleGetPdf = async () => {
    setPdfLoading(true);
    try {
      const queryString = getQueryString(queryParams);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`/admin/shops/${shopId}/orders/export/pdf${queryString}`, {
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      });
      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      setPdfLoading(false);
      const a = linkRef.current;
      a.download = `${
        header?.belongsTo ? `${header?.belongsTo?.name} - ${header?.name}` : header?.name
      } Rendelések ${new Date().toLocaleDateString('hu-HU')} .pdf`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      setPdfLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchHeader({ id: galleryId });
    fetchCover({ id: galleryId });
    fetchInnerGroups({ id: galleryId });
    fetchAllGroups();
    setCurrentPage(1);
    setPageSize(10);
    getModifiers();
  }, [galleryId]);

  const refresh = () => {
    fetchInnerGroups({ id: galleryId });
    fetchAllGroups();
  };

  useEffect(() => {
    if (header && header.belongsTo) {
      fetchParentHeader({ id: header.belongsTo.id });
    }
  }, [header]);

  useEffect(() => {
    fetchProducts({
      id: galleryId,
      queryString: getProductQueryString({
        page: currentPage,
        size: pageSize,
        sort: 'name'
      })
    });
    fetchAllProducts({
      id: galleryId
    });
  }, [galleryId, currentPage, pageSize]);

  const editGroupModalRef = createRef(null);
  const editGroupModalRef2 = createRef(null);
  const linkRef = useRef(null);

  const { deleteSelectedGroups, deleteSelectedProducts, handleSelectAll, selectItems, enableSelectedProducts } =
    useBatchLogic(
      fetchInnerGroups,
      innerGroups,
      setInnerGroups,
      deleteGroup,
      fetchProducts,
      products,
      setProducts,
      putProduct,
      deleteProduct,
      setConfirmProdOpen,
      galleryId,
      getQueryString,
      setSelected,
      fetchAllProducts
    );

  return header ? (
    <Container maxWidth={'xl'} className={classes.container}>
      <a ref={linkRef} />
      <BreadcrumbWrapper className={classes.breadcrumbWrapper}>
        <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
          <Link onClick={uploadLoading ? () => {} : routeChange} className={classes.rootLink}>
            <Typography
              style={{
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
              }}>
              Iskolák
            </Typography>
          </Link>
          <Breadcrumb header={header} parentHeader={parentHeader} />
        </Breadcrumbs>
      </BreadcrumbWrapper>

      <EditGroupDialog
        editModal={editModal}
        Transition={Transition}
        editGroupModalRef={editGroupModalRef}
        toggleEditModal={toggleEditModal}
        header={header}
        parent={parentHeader}
        refresh={async () => {
          await fetchHeader({ id: galleryId });
          await fetchInnerGroups({ id: galleryId });
          await fetchProducts({
            id: galleryId,
            queryString: getProductQueryString({
              page: currentPage,
              size: pageSize,
              sort: 'name'
            })
          });
        }}
        products={products}
      />
      <NewGroupDialog
        createModal={createModal}
        editGroupModalRef={editGroupModalRef2}
        transition={Transition}
        toggleCreateModal={toggleCreateModal}
        parent={parentHeader}
        parentGroup={header}
        refresh={() => refresh()}
      />
      <HomeWrapper>
        <BackgroundContainer>
          {coverLoading ? (
            <LoadIndicator height={385} color={'whitesmoke'} />
          ) : (
            <>
              <BackgroundImage src={cover} />
              <Button component='label' variant='contained' className={classes.editbutton}>
                <ImageUploader
                  image={cover}
                  setImage={setCover}
                  handleDelete={handleDelete}
                  id={galleryId}
                  url={`/admin/shops/${shopId}/groups/${header.id}/cover`}
                  fullWidth={true}
                />
              </Button>
            </>
          )}
        </BackgroundContainer>
        <TitleContainer>
          {header?.name !== 'Csoportképek' && (
            <ButtonWrapper>
              <Button variant='outlined' className={classes.ordersBtn} onClick={() => handleGetPdf()}>
                {pdfLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <PictureAsPdfIcon
                    style={{
                      color: theme.palette.primary.backgroundPrimary
                    }}
                  />
                )}
                Rendelések
              </Button>
              <Button variant='outlined' className={classes.ordersBtn} onClick={handleGotoOrders}>
                <ExitToAppIcon size={20} />
                Ugrás a rendelésekhez
              </Button>
            </ButtonWrapper>
          )}
          <Title>{header.name}</Title>
          <div
            style={{
              width: '100%',
              marginBottom: '1rem',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              alignItems: 'center'
            }}>
            {header?.modifiers?.map((el) => {
              return el.priority === 1 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    columnGap: '1.5rem'
                  }}>
                  <div
                    style={{
                      width: '120px',
                      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                    }}>
                    {el.name}:
                  </div>
                  <div
                    style={{
                      width: '120px',
                      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                    }}>
                    {new Date() <
                    new Date(
                      header?.productGroupModifierValidities?.find(
                        (val) => getUriId(val.modifierUriSegment) === getUriId(el.modifierUriSegment)
                      )?.validUntil
                    )
                      ? header?.productGroupModifierValidities
                          ?.find((val) => getUriId(val.modifierUriSegment) === getUriId(el.modifierUriSegment))
                          ?.validUntil?.substring(0, 10)
                      : 'Lejárt'}
                  </div>
                </div>
              ) : (
                ''
              );
            })}
            {header?.isPrinted || header?.isPrintingDone ? (
              <div>
                Az adott galéria már el lett küldve nyomtatásra, a nyomtatott képek értékesítése csak a galéria újbóli
                megnyitása után lesz elérhető.
              </div>
            ) : (
              ''
            )}
          </div>
          <Button disabled={uploadLoading} component='label' variant='contained' className={classes.editGroupButton}>
            <EditIcon className={classes.editicon} />
            {header && header.belongsTo
              ? header.name === 'Csoportképek'
                ? 'Csoportképek beállításai'
                : 'Osztály beállításai'
              : 'Iskola beállításai'}
            <input type='button' hidden onClick={toggleEditModal} />
          </Button>
          {loading && (
            <UpdateIndicator>
              Galériák frissítése folyamatban
              <CircularProgress
                size={20}
                style={{
                  color: theme.palette.primary.backgroundPrimary,
                  marginLeft: '0.5rem'
                }}
              />
            </UpdateIndicator>
          )}
        </TitleContainer>
      </HomeWrapper>
      <MultipleControls
        style={{
          display: (header.belongsTo || innerGroups === null || innerGroups.length === 0) && 'none'
        }}>
        <Button
          variant='contained'
          disabled={!selected}
          className={classes.selectedDelete}
          onClick={() => {
            setConfirmProdOpen(true);
          }}>
          {groupDeleting ? (
            <CircularProgress className={classes.deleteIcon} size={22} />
          ) : (
            <DeleteIcon className={classes.deleteIcon} />
          )}
          Kijelöltek törlése
        </Button>
        <div>
          <input
            type='checkbox'
            onClick={(e) => {
              handleSelectAll(innerGroups, e, true);
            }}
            style={{
              width: '1.2rem',
              height: '1.2rem',
              cursor: 'pointer'
            }}
          />
          Összes kijelölése
        </div>
      </MultipleControls>
      <DeleteDialog
        confirmOpen={confirmProdOpen}
        deleteCoverModalRef={deleteProdModalRef}
        setConfirmOpen={setConfirmProdOpen}
        handleDelete={deleteSelectedGroups}
      />
      <GroupsWrapper>
        {innerGroupLoading ? (
          <LoadingWrapper>
            <Typography>Egy pillanat ...</Typography>
          </LoadingWrapper>
        ) : (
          <>
            {' '}
            {header && !header.belongsTo && <AddGroupCard toggleCreateModal={toggleCreateModal} disabled={disabled} />}
            {innerGroups
              ?.sort((a, b) => {
                return a.name.localeCompare(b.name);
              })
              .map((innerGroup) => {
                return (
                  <GroupItem
                    shopId={shopId}
                    disabled={uploadLoading}
                    key={innerGroup.id}
                    groups={innerGroups}
                    group={innerGroup}
                    selectGroups={() => selectItems(innerGroups, innerGroup.id, true)}
                    selected={innerGroup.selected}
                    putGroup={putGroup}
                    size={0.61}
                    refetch={refresh}
                    count={237}
                  /> // TODO Getting size and count from backend
                );
              })}
          </>
        )}
      </GroupsWrapper>
      {header.belongsTo && header && (
        <BugWrapper>
          <Uploader
            group={header}
            fetchProducts={async () => {
              await fetchProducts({
                id: galleryId,
                queryString: getProductQueryString({
                  page: currentPage,
                  size: pageSize,
                  sort: 'name'
                })
              });
              await fetchAllProducts({
                id: galleryId
              });
            }}
            disabled={uploadDisabled}
            products={allProducts}
          />
          <Results />
        </BugWrapper>
      )}

      {products && (
        <>
          <MultipleControls>
            <Button
              variant='contained'
              disabled={!selected}
              className={classes.selectedDelete}
              onClick={() => {
                enableSelectedProducts();
              }}>
              {productEnabling ? (
                <CircularProgress
                  style={{
                    color: selected ? theme.palette.primary.textPrimary : 'rgb(166,166,196)',
                    marginRight: '1rem'
                  }}
                  size={22}
                />
              ) : (
                <FlipIcon
                  className={classes.deactivate}
                  style={{
                    color: selected ? theme.palette.primary.textPrimary : 'rgb(166,166,196)',
                    marginRight: '1rem'
                  }}
                />
              )}
              Aktiválás/deaktiválás
            </Button>
            <Button
              variant='contained'
              disabled={!selected}
              className={classes.selectedDelete}
              onClick={() => {
                setConfirmProdOpen(true);
              }}>
              {productDeleting ? (
                <CircularProgress className={classes.deleteIcon} size={22} />
              ) : (
                <DeleteIcon className={classes.deleteIcon} />
              )}
              Kijelöltek törlése
            </Button>

            <div>
              <input
                type='checkbox'
                onClick={(e) => {
                  handleSelectAll(products, e, false);
                }}
                style={{
                  width: '1.2rem',
                  height: '1.2rem',
                  cursor: 'pointer'
                }}
              />
              {productsLength <= pageSize ? 'Összes kijelölése' : 'Oldal kijelölése'}
            </div>
          </MultipleControls>
          <DeleteDialog
            confirmOpen={confirmProdOpen}
            deleteCoverModalRef={deleteProdModalRef}
            setConfirmOpen={setConfirmProdOpen}
            handleDelete={deleteSelectedProducts}
          />
          <TablePagination
            hidden={productsLength <= 10 || productsLength === undefined}
            className={classes.pagination}
            component='div'
            count={productsLength}
            page={currentPage !== 0 && currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Megjelenített képek száma'}
          />
          <ProductsWrapper>
            {products?.map((product) => (
              <Product
                shopId={shopId}
                selectProduct={() => selectItems(products, product.id, false)}
                key={product.id}
                selected={product.selected}
                refresh={async () => {
                  await fetchProducts({
                    id: galleryId,
                    queryString: getProductQueryString({
                      page: currentPage,
                      size: pageSize,
                      sort: 'name'
                    })
                  });
                  await fetchAllProducts({ id: galleryId });
                }}
                productHeader={product}
                modifiers={modifiers}
                parentGroup={header}
              />
            ))}
          </ProductsWrapper>
          <TablePagination
            hidden={productsLength <= 10 || productsLength === undefined}
            component='div'
            className={classes.pagination}
            count={productsLength}
            page={currentPage !== 0 && currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Megjelenített képek száma'}
          />
        </>
      )}
    </Container>
  ) : (
    <LoadIndicator height={400} />
  );
};

export default Group;
