/** @format */

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled, { ThemeProvider as StyledProvider } from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainTheme from 'styles/MainTheme';
import Header from 'components/common/Header/Header';
import DrawerMenu from 'components/common/Header/DrawerMenu/DrawerMenu';
import AuthService from 'services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Login from 'pages/Login/Login';
import { setActive } from 'redux/slices/ShopSlice';
import { useUserApi } from 'pages/Register/hooks/useUserApi';
import { setScopes } from 'redux/slices/ScopeSlice';
import Alert from 'components/common/Alert/Alert';
import { Box } from '@mui/material';
import { useInvoicingSettingsAPI } from 'pages/PhotoAdmin/AdminMenu/Invoicing/hooks/useInvoicingSettingsAPI';

import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Profile from 'pages/PhotoAdmin/Profile/Profile';
import SelectShop from 'pages/PhotoAdmin/SelectShop/SelectShop';
import Shop from 'pages/PhotoAdmin/SelectShop/Shop/Shop';
import SuperAdminMenu from 'pages/SuperAdmin/SuperAdminMenu';
import Dashboard from 'pages/SuperAdmin/Dashboard/Dashboard';
import Users from 'pages/SuperAdmin/Users/Users';
import User from 'pages/SuperAdmin/Users/User/User';
import Notifications from 'pages/SuperAdmin/Notifications/Notifications';
import PriceAdjustment from 'pages/SuperAdmin/PriceAdjustment/PriceAdjustment';
import Roles from 'pages/PhotoAdmin/AdminMenu/Roles/Roles';
import Invoicing from 'pages/PhotoAdmin/AdminMenu/Invoicing/Invoicing';
import Documents from 'pages/PhotoAdmin/AdminMenu/Documents/Documents';
import Register from 'pages/Register/Register';
import AdminMenu from 'pages/PhotoAdmin/AdminMenu/AdminMenu';
import PrinterAdminSite from 'pages/PrinterAdmin/PrinterAdminSite';
import Orders from 'pages/PhotoAdmin/AdminMenu/Orders/Orders';
import Printing from 'pages/PhotoAdmin/AdminMenu/Printing/Printing';
import Templates from 'pages/PhotoAdmin/AdminMenu/Templates/Templates';
import OrderDetails from 'pages/PhotoAdmin/AdminMenu/Orders/OrderDetails/OrderDetails';
import Groups from 'pages/PhotoAdmin/AdminMenu/Groups/Groups';
import Group from 'pages/PhotoAdmin/AdminMenu/Groups/Group/Group';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Blogs from 'pages/SuperAdmin/Blogs/Blogs';
import PhotoReports from 'pages/PhotoAdmin/AdminMenu/PhotoReports/PhotoReports';
import Payments from 'pages/SuperAdmin/Payments/Payments';
import Footer from './components/common/Footer/Footer';
import Subscription from 'pages/Subscription/Subscription';
import SubscriptionBundles from 'pages/Subscription/SubscriptionBundles/SubscriptionBundles';
const BodyWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  // background: #dedede;
  @media (min-width: 900px) {
    margin-top: 0;
  }
`;

function App() {
  const [adminOptionsOpen, setAdminOptionsOpen] = useState(false);
  const [siteOptionsOpen, setSiteOptionsOpen] = useState(false);
  const [profileOptionsOpen, setProfileOptionsOpen] = useState(false);
  const [ownShop, setOwnShop] = useState(false);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const alert = useSelector((state) => state.nonPersistedReducers?.alertSliceReducer?.alert);

  const { getCurrentUser, user } = useUserApi();
  const { getInvoiceVendors } = useInvoicingSettingsAPI();
  const dispatch = useDispatch();
  // const history = useHistory();

  const getShopByUrl = () => {
    const params = window.location.pathname.split('/');
    const idx = params.findIndex((param) => param === 'shops');
    if (idx !== -1 && params.length > idx + 2) {
      const shopIdFromUrl = params[idx + 1];
      const intShopId = parseInt(shopIdFromUrl);
      if (!isNaN(intShopId)) {
        dispatch(setActive(intShopId));
      } else {
        dispatch(setActive(-1));
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (shopId !== -1) {
      getInvoiceVendors();
    }
  }, [shopId]);

  //PAGE REDIRECT AT FIRST LOAD NOT WORKING

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      getCurrentUser();
    }
  }, []);

  // IT CAUSES UNNECESSARY RERENDERING GLITCH
  // useEffect(() => {
  //   if (AuthService.isLoggedIn() && user) {
  //     if (
  //       (user?.ownedShops?.length === 0 &&
  //         user?.maintainedShops?.length === 0) ||
  //       user?.maintainedShops?.length > 0
  //     ) {
  //       dispatch(setActive(-1));
  //     } else if (
  //       user?.ownedShops?.length > 0 &&
  //       user?.maintainedShops?.length === 0
  //     ) {
  //       dispatch(setActive(user?.ownedShops[0]?.id));
  //     }
  //   }
  // }, [user]);

  useEffect(() => {
    if (!ownShop) {
      dispatch(setScopes(user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes));
    }
    shopChecker();
  }, [user, shopId]);

  const toggleAdminOptions = () => {
    setAdminOptionsOpen(!adminOptionsOpen);
    setSiteOptionsOpen(false);
    setProfileOptionsOpen(false);
  };
  const toggleSiteOptions = () => {
    setSiteOptionsOpen(!siteOptionsOpen);
    setAdminOptionsOpen(false);
    setProfileOptionsOpen(false);
  };
  const toggleProfileOptions = () => {
    setProfileOptionsOpen(!profileOptionsOpen);
    setAdminOptionsOpen(false);
    setSiteOptionsOpen(false);
  };
  const toggleOptionsHidden = () => {
    setSiteOptionsOpen(false);
    setAdminOptionsOpen(false);
  };

  // Temporary drawer
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const shopChecker = () => {
    user?.ownedShops?.find((shop) => shop?.id === shopId) ? setOwnShop(true) : setOwnShop(false);
  };

  useEffect(() => {
    getShopByUrl();
  }, [shopId]);

  return (
    <StyledProvider theme={MainTheme}>
      <ThemeProvider theme={MainTheme}>
        <BodyWrapper onClick={toggleOptionsHidden}>
          {
            <Header
              adminOptionsOpen={adminOptionsOpen}
              siteOptionsOpen={siteOptionsOpen}
              toggleAdminOptions={toggleAdminOptions}
              toggleSiteOptions={toggleSiteOptions}
              toggleDrawer={toggleDrawer}
              ownShop={ownShop}
            />
          }
          <DrawerMenu
            drawer={drawer}
            toggleDrawer={toggleDrawer}
            setDrawer={setDrawer}
            siteOptionsOpen={siteOptionsOpen}
            toggleSiteOptions={toggleSiteOptions}
            adminOptionsOpen={adminOptionsOpen}
            toggleAdminOptions={toggleAdminOptions}
            profileOptionsOpen={profileOptionsOpen}
            toggleProfileOptions={toggleProfileOptions}
            user={user}
          />
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar
            newestOnTop
            limit={5}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            <Route exact path='/settings/profile' element={<Profile />} />
            {/*
            NEEDED WHEN SUBSCRIPTION IS READY
            <Route path={`/settings/subscriptions`} element={<Subscription />} />
            <Route path={`/settings/subscriptions-bundles`} element={<SubscriptionBundles />} /> */}
            <Route path='/shops' element={<SelectShop />} children={<Route path='/shops/:id' element={<Shop />} />} />
            {/*SITETYPE_SELECT*/}
            {user?.groups?.find((group) => group === 'dt_admin') && (
              <>
                {/*SUPER_ADMIN*/}
                <Route path='/' element={<Navigate replace to={'/superadmin/dashboard'} />} />
                <Route
                  path='/superadmin'
                  element={<SuperAdminMenu />}
                  children={
                    <>
                      <Route exact path={`/superadmin/dashboard`} element={<Dashboard />} />
                      <Route exact path={`/superadmin/payments`} element={<Payments />} />
                      <Route exact path={`/superadmin/users`} element={<Users />} />
                      <Route exact path={`/superadmin/users/:id`} element={<User />} />
                      <Route exact path={`/superadmin/pricing`} element={<PriceAdjustment />} />
                      <Route exact path={`/superadmin/notifications`} element={<Notifications />} />
                      <Route exact path={`/superadmin/blogs`} element={<Blogs />} />

                      <Route
                        path={`/superadmin/*`}
                        element={<ErrorPage errorCode={404} errorText={'Content not found'} user={user} />}
                      />
                    </>
                  }
                />
                <Route exact path={`/superadmin`} element={<Navigate replace to={`/superadmin/dashboard`} />} />
              </>
            )}
            {user?.groups?.find((group) => group === 'users') && (
              <Route
                path='/'
                element={
                  <>
                    {shopId !== -1 ? (
                      <Navigate replace to={`/shops/${shopId}/administration/orders`} />
                    ) : (
                      <Navigate replace to={`/shops`} />
                    )}
                  </>
                }
              />
            )}{' '}
            {user?.groups?.find((group) => group === 'printer_admin') && (
              <Route path='/' element={<Navigate replace to={'/printer'} />} />
            )}
            {/*PRINTER_ADMIN*/}
            <Route path='/printer' element={<PrinterAdminSite />} />
            {/*PHOTO_ADMIN*/}
            {/*SHOP_ADMINISTRATION*/}
            <Route
              path={`/shops/${shopId}/administration`}
              element={<AdminMenu />}
              children={
                <>
                  <Route path={`/shops/${shopId}/administration/orders`} element={<Orders />} />
                  <Route path={`/shops/${shopId}/administration/orders/:id`} element={<OrderDetails />} />
                  <Route path={`/shops/${shopId}/administration/reports`} element={<PhotoReports />} />
                  <Route path={`/shops/${shopId}/administration/printing`} element={<Printing />} />
                  <Route path={`/shops/${shopId}/administration/templates`} element={<Templates />} />
                  <Route path={`/shops/${shopId}/administration/galleries`} element={<Groups />} />
                  <Route path={`/shops/${shopId}/administration/galleries/:id`} element={<Group />} />
                  <Route path={`/shops/${shopId}/administration/roles`} element={<Roles />} />

                  <Route path={`/shops/${shopId}/administration/invoicing`} element={<Invoicing />} />
                  <Route path={`/shops/${shopId}/administration/documents`} element={<Documents />} />
                </>
              }
            />
            <Route path='/register' element={<Register />} />
            <Route
              path={`/shops/${shopId}/*`}
              element={<ErrorPage errorCode={404} errorText={'Content not found'} user={user} />}
            />
            <>
              {user?.ownedShops?.length === 1 && user?.maintainedShops?.length === 0 ? (
                <Route
                  path='/shops'
                  element={<Navigate replace to={`/shops/${user?.ownedShops[0]?.id}/administration/orders`} />}
                />
              ) : (
                <Route exact path='/shops' element={<SelectShop />} />
              )}
            </>
            {!AuthService.isLoggedIn() && location.pathname !== '/register' && <Route path='/' element={<Login />} />}
            <Route path={`*`} element={<ErrorPage errorCode={404} errorText={'Content not found'} user={user} />} />
          </Routes>
          <Box
            style={{
              position: 'fixed',
              minWidth: '100px',
              height: '80px',
              overflow: 'hidden',
              bottom: '0.8rem',
              left: '1rem',
              paddingLeft: '1rem'
            }}>
            {' '}
            {alert !== '' && <Alert maxWidth={alert === 'no_vendor' ? 395 : 645} />}
          </Box>
          <Footer />
        </BodyWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </StyledProvider>
  );
}

export default App;
