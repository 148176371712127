import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 1s',
    padding: '1rem'
  },
  breadcrumb: {
    margin: '1rem',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    color: theme.palette.primary.backgroundSecondary,
    outline: 'none !important'
  },
  buttonClose: {
    padding: '0 !important',
    minWidth: '0 !important'
  },



  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    height: "100%",
    margin: "0 !important",
    padding: "0 !important",
  },
  sideContainer: {
    maxWidth: "1100px",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.primary.backgroundSecondary,
    [theme.breakpoints.up("sm")]: {
      padding: "1.5rem 1.5rem 0 1.5rem !important",
      margin: "2rem !important",
    },
  },
  topContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  select: {
    color: theme.palette.primary.textPrimary,
    width: '250px'
  },
  loadBtn: {
    margin: "1rem 0",
  },
  buttons: {
    margin: "1rem 0",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
