import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    transition: 'all 1s',
    padding: '0 !important',
    margin: '0 !important'
  },

  container: {
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    height: '100% !important',
    width: '100% !important',
    padding: '0 !important'
  },
  breadcrumb: {
    margin: '1rem !important',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial !important`,
    color: `${theme.palette.primary.backgroundSecondary} !important`,
    outline: 'none !important'
  },
  desktopTitle: {
    fontSize: '2rem',
    padding: '0 1rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  paper: {
    width: '91%',
    margin: '1.5rem 0',
    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  },
  addBtn: {
    width: '50%'
  }
}));

export const DocumentDownload = styled.a`
  margin-left: auto;
  color: ${({ theme }) => theme.palette.primary.backgroundSecondary};
  cursor: pointer;
`;
