import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    flexDirection: 'column'
  },
  content: {
    height: '100%'
  },
  breadcrumb: {
    margin: '1rem',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    outline: 'none !important'
  },
  settingsWrapper: {
    backgroundColor: 'white',
    borderRadius: '0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0'
    }
  },
  tabs: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tab: {
    height: '12px !important'
  }
}));
