import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useShopAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useShopApi';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ScopeSelector from 'components/PhotoAdminComponents/Settings/Roles/ScopeSelector/ScopeSelector';
import { useUserApi } from 'pages/Register/hooks/useUserApi';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import UserSearch from 'components/PhotoAdminComponents/Settings/Roles/UserSearch/UserSearch';
import { Breadcrumbs, Container, CssBaseline, Zoom } from '@mui/material';

const Roles = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const loggedUser = useSelector((state) => state.nonPersistedReducers.userSliceReducer.currentUser);

  const [maintainers, setMaintainers] = useState();

  const { getAllScopes, allScopes, putShop, getShop, shop } = useShopAPI();

  const { getCurrentUser, getAllUsers, allUsers } = useUserApi();

  const translateScopes = (scope) => {
    if (scope === 'MANAGE_ORDERS') {
      return 'RENDELÉSEK_KEZELÉSE';
    }
    if (scope === 'VIEW_ORDERS') {
      return 'RENDELÉSEK_MEGTEKINTÉSE';
    }
    if (scope === 'MANAGE_SHOP') {
      return 'JOGOSULTSÁGOK_KEZELÉSE';
    }
    if (scope === 'VIEW_SHOP') {
      return 'JOGOSULTSÁGOK_MEGTEKINTÉSE';
    }
    if (scope === 'MANAGE_PRODUCTS') {
      return 'GALÉRIÁK_KEZELÉSE';
    }
    if (scope === 'VIEW_PRODUCTS') {
      return 'GALÉRIÁK_MEGTEKINTÉSE';
    }
    if (scope === 'MANAGE_PRICING') {
      return 'ÁRAZÓ_SABLONOK_KEZELÉSE';
    }
    if (scope === 'VIEW_PRICING') {
      return 'ÁRAZÓ_SABLONOK_MEGTEKINTÉSE';
    }
    if (scope === 'VIEW_PAYMENTS') {
      return 'FIZETÉSEK_MEGTEKINTÉSE';
    }
    if (scope === 'VIEW_INVOICES') {
      return 'SZÁMLÁK_MEGTEKINTÉSE';
    }
    if (scope === 'VIEW_INVOICE_VENDORS') {
      return 'SZÁMLÁZÓ_VENDOROK_MEGTEKINTÉSE';
    }
    if (scope === 'MANAGE_INVOICE_VENDORS') {
      return 'SZÁMLÁZÓ_VENDOROK_KEZELÉSE';
    }
  };

  const [m, setM] = useState(null);

  const handleRefresh = () => {
    getCurrentUser();
    getShop();
  };

  const removeMaintainer = async (userId) => {
    setMaintainers(maintainers.filter((maintainer) => maintainer.user.id !== userId));
    const body = {
      ...shop,
      maintainers: shop.maintainers.filter((maintainer) => maintainer.user.id !== userId)
    };
    await putShop({ body: body });
    handleRefresh();
  };

  useEffect(() => {
    getAllScopes();
    if (shopId !== -1) {
      getShop();
    }
  }, [shopId]);

  useEffect(() => {
    setMaintainers(shop?.maintainers);
  }, [shop]);

  const [currentUser, setCurrentUser] = useState(null);
  const [currentScopes, setCurrentScopes] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [loading, setLoading] = useState();

  const clearSelection = () => {
    setCurrentScopes(null);
    setCurrentUser(null);
    setCurrentId(null);
  };

  const handleUpdate = async () => {
    setLoading(true);
    await putShop({
      body: {
        ...shop,
        maintainers: maintainers
      }
    });
    setLoading(false);
    clearSelection();
    handleRefresh();
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
        <Typography variant='body1'>Bolt kezelői</Typography>
      </Breadcrumbs>

      <Zoom in={true} timeout={1000}>
        <Container maxWidth className={classes.container}>
          {' '}
          <Paper className={classes.paper}>
            <UserSearch
              shop={shop}
              putShop={putShop}
              getAllUsers={getAllUsers}
              handleRefresh={handleRefresh}
              allUsers={allUsers}
              maintainers={maintainers}
            />

            <Table style={{ height: '70%' }}>
              <TableHead
                style={{
                  background: theme.palette.primary.backgroundSecondary
                }}>
                <TableRow>
                  <TableCell width='5%' className={classes.hcell}>
                    #
                  </TableCell>
                  {/* <TableCell width="20%" className={classes.hcell}>
                    Azonosító
                  </TableCell> */}
                  <TableCell width='15%' className={classes.hcell}>
                    Felhasználónév
                  </TableCell>
                  <TableCell width='60%' colSpan={2} className={classes.hcell} style={{ maxWidth: '60px' }}>
                    Jogosultságok
                  </TableCell>
                  <TableCell width='10%' className={classes.hcell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {maintainers &&
                  maintainers
                    ?.filter((user) => user.user.username !== 'system-agent')
                    ?.map(({ user, scopes, id }, idx) => (
                      <TableRow key={`maintainer_user_${user?.id}`}>
                        <TableCell width='5%' className={classes.bcell}>
                          {idx + 1}
                        </TableCell>

                        <TableCell width='15%' className={classes.bcell}>
                          {user?.username}
                        </TableCell>
                        <TableCell
                          width='60%'
                          colSpan={2}
                          className={classes.bcell}
                          style={{ overflow: 'hidden', maxWidth: '60px' }}>
                          {scopes?.map((scope) => translateScopes(scope))}
                        </TableCell>
                        <TableCell width='10%' className={classes.bcell}>
                          {loggedUser?.ownedShops[0]?.id !== shopId &&
                          !loggedUser?.maintainedShops
                            ?.find((shop) => shop.shop.id === shopId)
                            ?.scopes?.includes('MANAGE_SHOP') ? (
                            <Tooltip title='Csak a megfelelő BOLT_KEZELÉSE jogosultság birtokában módosíthat'>
                              <EditIcon
                                style={{
                                  marginRight: '1rem',
                                  color: 'lightgrey'
                                }}
                                className={classes.icon}
                                onClick={() => {}}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title='Felhasználó jogosultságainak szerkesztése'>
                              <EditIcon
                                style={{ marginRight: '1rem' }}
                                className={classes.icon}
                                color='primary'
                                onClick={() => {
                                  setCurrentUser(user);
                                  setCurrentScopes(scopes);
                                  setCurrentId(id);
                                }}
                              />
                            </Tooltip>
                          )}
                          {loggedUser?.ownedShops[0]?.id !== shopId &&
                          !loggedUser?.maintainedShops
                            ?.find((shop) => shop.shop.id === shopId)
                            ?.scopes?.includes('MANAGE_SHOP') ? (
                            <Tooltip title='Csak a megfelelő BOLT_KEZELÉSE jogosultság birtokában törölhet'>
                              <DeleteIcon style={{ color: 'lightgrey' }} className={classes.icon} onClick={() => {}} />
                            </Tooltip>
                          ) : (
                            <Tooltip title='Felhasználó törlése'>
                              <DeleteIcon color='error' className={classes.icon} onClick={() => setM(user)} />
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Container>
      </Zoom>

      <ConfirmDialog
        open={m}
        onOk={() => {
          removeMaintainer(m?.id);
          setM(null);
        }}
        onClose={() => setM(null)}
        title={`Biztosan törölni akarod ${m?.username} felhasználót, a boltod kezelői közül?`}
        functionButtonColor={theme.palette.primary.success}
        functionButtonText={'Igen'}
        closeButtonText={'Nem'}
        multipleButtons
      />
      <Dialog open={currentUser} fullWidth maxWidth='lg'>
        <DialogTitle>
          <Typography variant='h4' color='primary'>
            Jogosultságok kezelése
          </Typography>
        </DialogTitle>
        {allScopes && (
          <DialogContent>
            <ScopeSelector
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              currentScopes={currentScopes}
              setCurrentScopes={setCurrentScopes}
              maintainers={maintainers}
              setMaintainers={setMaintainers}
              allScopes={allScopes}
              currentId={currentId}
              shop={shop}
              putShop={putShop}
              handleRefresh={handleRefresh}
              handleTranslate={translateScopes}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={clearSelection}
            variant='contained'
            style={{
              backgroundColor: theme.palette.error.dark,
              color: 'whitesmoke'
            }}>
            Mégse
          </Button>
          <Button onClick={handleUpdate} variant='contained' color='primary'>
            {loading ? <CircularProgress size={24} style={{ color: theme.palette.primary.textPrimary }} /> : 'Mentés'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Roles;
