import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.5rem',
    columnGap: '1.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    width: '66%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    }
  },
  middleContainer: {
    display: 'flex',
    rowGap: '1.5rem',
    columnGap: '1.5rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  middleLeft: {
    padding: '1rem',
    boxShadow: '0 4px 15px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    borderRadius: '15px',
    width: '65%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  middleRight: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    boxShadow: '0 4px 15px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    borderRadius: '15px',
    width: '35%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  rightBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  subscriptionInfo: {
    backgroundColor: '#b0043f',
    color: 'white',
    padding: '0.3rem 2rem 0.3rem 1rem',
    borderRadius: '0 15px 15px 0',
    marginLeft: '-1rem',
    maxWidth: '300px'
  },
  title: {
    textAlign: 'center'
  },
  logoContainer: {
    width: '150px',
    margin: 'auto'
  },
  bottom: {
    padding: '1rem',
    boxShadow: '0 4px 15px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    borderRadius: '15px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));
