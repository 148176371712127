import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  accordion_base: {
    position: 'relative',
    margin: theme.spacing(2, 0),
    borderRadius: '2rem',
    backgroundColor: '#b0043f'
  },
  opened: {
    '& $accordion_head': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    '& $accordion_description': {
      display: 'block'
    },
    '& $accordion_icon': {
      transform: 'translateY(-50%) rotate(180deg)'
    }
  },
  accordion_head: {
    position: 'relative',
    backgroundColor: '#b0043f',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.24)',
    borderRadius: '2rem',
    fontWeight: 600,
    padding: theme.spacing(2, 3),
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '1rem',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  accordion_icon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'transform 0.1s ease-in-out',
    color: 'white'
  },
  accordion_description: {
    display: 'none',
    color: 'white',
    borderBottomLeftRadius: '2rem',
    borderBottomRightRadius: '2rem',
    padding: theme.spacing(3)
  }
}));
