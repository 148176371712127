import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    outsideCard: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    mainContainer: {
      minWidth: '325px',
      maxWidth: '325px',
      borderRadius: '20px',
      padding: '1.5rem',
      boxSizing: 'border-box'
    },
    mainContainerRed: {
      minWidth: '325px',
      maxWidth: '325px',
      borderRadius: '20px',
      border: 'solid #b0043f 2px',
      padding: '1.5rem',
      boxSizing: 'border-box'
    },
    fadeIn: {
      opacity: 1,
      maxHeight: '100%',
      animation: `$fadeIn 0.25s ease-in-out forwards`
    },
    fadeOut: {
      opacity: 0,
      maxHeight: 0,
      visibility: 'hidden',
      animation: `$fadeOut 0.25s ease-in-out forwards`
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },
    '@keyframes fadeOut': {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0,
        visibility: 'hidden'
      }
    },
    innerPart: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '2rem',
      alignItems: 'center',
      textAlign: 'center'
    },
    logoContainer: {
      width: '150px'
    },
    infoSection: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1rem'
    },
    payment: {
      fontWeight: 'bold',
      fontSize: '20px'
    },
    introButton: {
      background: (theme) => theme.palette.primary.backgroundSecondary,
      color: (theme) => theme.palette.primary.textPrimary,
      border: 'none',
      height: '35px',
      padding: '0 2rem',
      cursor: 'pointer',
      textAlign: 'center',
      textDecoration: 'none',
      borderRadius: '15px',
      fontSize: '1rem',
      alignSelf: 'center',
      transition: 'background 300ms',
      fontFamily: (theme) => `${theme.typography.main}, Sans-serif, Arial`,
      marginTop: '1rem',
      '&:hover': {
        opacity: '80%'
      }
    },
    detailedContainer: {
      display: 'flex',
      columnGap: '2rem'
    },
    details: {
      display: 'flex',
      rowGap: '2rem',
      flexDirection: 'column'
    },
    sentenceContainer: {
      display: 'flex',
      rowGap: '1rem',
      flexDirection: 'column'
    }
  };
});
