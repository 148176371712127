import styles from './ProfileAvatar.module.css';
import AuthService from '../../../../../services/AuthService';
import AvatarIcon from '../AvatarIcon/AvatarIcon';

const ProfileAvatar = () => {
  return (
    <div className={styles.container}>
      <AvatarIcon width={40} height={40} />
      <div>
        <div className={styles.name}>{AuthService.getFirstName()}</div>
        <div className={styles.role}>User</div>
      </div>
    </div>
  );
};

export default ProfileAvatar;
