/** @format */

import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import { MdDescription } from 'react-icons/md';

import { useFooterData } from './FooterData';

import {
  OuterWrapper,
  FooterWrapper,
  Sections,
  SectionWrapper,
  CopyrightWrapper,
  SectionContent,
  SectionTitle,
  TitleWrapper,
  SectionA,
  SectionLink,
  useStyles,
  PaymentVendorLogoWrapper,
  PaymentVendorLogo
} from './styles';
import simplepayPng from 'assets/images/simplepay_otp_bankcard_hu_top.png';
// import barionStrip from "assets/images/barionStrip.svg";
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Footer = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const baseDocuments = useSelector((state) => state.nonPersistedReducers?.documentSliceReducer?.documents);

  const { FooterData } = useFooterData(props?.shopData);

  const { getDocuments, downloadDocuments, downloadBaseDocuments, documents } = useDocumentsApi();

  useEffect(() => {
    if (shopId && shopId !== -1) {
      getDocuments();
    }
  }, [shopId]);

  const copyright = () => {
    return (
      <Typography
        align='center'
        style={{
          fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
        }}>
        {'Copyright © '}
        <RouterLink className={classes.link} to='/'>
          iskolaévkönyv.hu
        </RouterLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  return (
    <OuterWrapper className={classes.outerWrapper} id='hirlevel'>
      <FooterWrapper>
        <Sections>
          {FooterData.sections.map((section, secIdx) => {
            return (
              <SectionWrapper key={`footer_section_${secIdx}`}>
                <TitleWrapper color={theme.palette.primary.textPrimary}>
                  <SectionTitle className={classes.title}>{section?.name}</SectionTitle>
                </TitleWrapper>
                <SectionContent>
                  {section?.links?.map((link, idx) => {
                    return (
                      <Fragment key={`footer_section_${secIdx}_link_${idx}`}>
                        {link?.external ? (
                          <>
                            <SectionA target='_blank' href={link?.link} rel='noreferrer' className={classes.link}>
                              {link?.icon} {link?.text}
                            </SectionA>
                          </>
                        ) : (
                          <>
                            <SectionLink
                              onClick={link?.to === '#' ? () => {} : () => window.scrollTo(0, 0)}
                              className={classes.link}
                              to={link?.link}>
                              {link?.icon} {link?.text}
                            </SectionLink>
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                </SectionContent>
              </SectionWrapper>
            );
          })}
          {FooterData.downloadSections.map((section, downIdx) => {
            return (
              <SectionWrapper key={`footer_download_${downIdx}`}>
                <TitleWrapper color={theme.palette.primary.textPrimary}>
                  <SectionTitle className={classes.title}>{section?.name}</SectionTitle>
                </TitleWrapper>
                <SectionContent>
                  {section?.name === 'Dokumentumok'
                    ? baseDocuments?.map((el) => {
                        if (el.name.includes('szerződési')) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <SectionA
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadBaseDocuments(el.id, el.name);
                                }}
                                className={classes.link}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: theme.palette.primary.backgroundSecondary
                                  }}
                                />{' '}
                                {el.name}
                              </SectionA>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                  {section?.name === 'Adatvédelem'
                    ? baseDocuments?.map((el) => {
                        if (el.name.includes('tájékoztató')) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <SectionA
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadBaseDocuments(el.id, el.name);
                                }}
                                className={classes.link}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: theme.palette.primary.backgroundSecondary
                                  }}
                                />{' '}
                                {el.name}
                              </SectionA>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                  {section?.links?.map((link, idx) => (
                    <Fragment key={`footer_download_${downIdx}_link_${idx}`}>
                      {link?.external ? (
                        <SectionA target='_blank' href={link?.link} rel='noreferrer' className={classes.link}>
                          {link?.icon} {link?.text}
                        </SectionA>
                      ) : (
                        <SectionA href={link?.href} download={link?.download} className={classes.link} key={idx}>
                          {link?.icon} {link?.text}
                        </SectionA>
                      )}
                    </Fragment>
                  ))}

                  {section?.name === 'Dokumentumok'
                    ? documents?.map((el) => {
                        if (el.name.includes('Általános szerződési feltételek') && shopId) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <SectionA
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadDocuments(el.id, el.name);
                                }}
                                className={classes.shopLink}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: theme.palette.primary.backgroundSecondary
                                  }}
                                />{' '}
                                {el.name}
                              </SectionA>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                  {section?.name === 'Adatvédelem'
                    ? documents?.map((el) => {
                        if (el.name.includes('Adatkezelési tájékoztató') && shopId) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <SectionA
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadDocuments(el.id, el.name);
                                }}
                                className={classes.link}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: theme.palette.primary.backgroundSecondary
                                  }}
                                />{' '}
                                {el.name}
                              </SectionA>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                </SectionContent>
              </SectionWrapper>
            );
          })}
        </Sections>

        <PaymentVendorLogoWrapper>
          <a href='https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf' target={'_blank'} rel='noreferrer'>
            <PaymentVendorLogo src={simplepayPng} alt='simplepay_logo' />
          </a>
        </PaymentVendorLogoWrapper>

        {/* <PaymentVendorLogoWrapper>
                    <a
                        href="https://secure.barion.com/Registration/Individual?lang=hu_HU"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <PaymentVendorLogo
                            src={barionStrip}
                            alt="barion_logo"
                        />
                    </a>
                </PaymentVendorLogoWrapper> */}
        <CopyrightWrapper>
          <SectionContent className={classes.link}>{copyright()}</SectionContent>
        </CopyrightWrapper>
      </FooterWrapper>
    </OuterWrapper>
  );
};

export default Footer;
