import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    transition: 'all 1s',
    padding: '0 !important',
    margin: '0 !important'
  },

  container: {
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    height: '100% !important',
    width: '100% !important',
    padding: '0 !important'
  },
  breadcrumb: {
    margin: '1rem !important',
    fontFamily: `${theme.typography.main}, Sans-serif, Arial !important`,
    color: `${theme.palette.primary.backgroundSecondary} !important`,
    outline: 'none !important'
  },
  paper: {
    width: '91%',
    margin: '1.5rem 0',
    [theme.breakpoints.up('md')]: {
      width: '98%'
    }
  },

  appearanceWrapper: {
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }
  },

  desktopTitle: {
    fontSize: '2rem',
    padding: '0 1rem',
    height: 0,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobileTitle: {
    padding: '1rem 0',
    fontSize: '2rem',
    textAlign: 'center',
    color: theme.palette.primary.backgroundSecondary,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },

  mobilePickerWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  desktopPickerWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hcell: {
    color: theme.palette.primary.textPrimary,
    fontFamily: `${theme.typography.main}, Arial, Sans-serif`
  },
  bcell: {
    fontFamily: `${theme.typography.main}, Arial, Sans-serif`
  },

  searchTitle: {
    padding: '1rem  0  0 1rem',
    fontSize: '1.2rem'
  },

  addBtn: {
    background: theme.palette.primary.success,
    color: theme.palette.primary.textPrimary,
    marginLeft: '1rem',
    opacity: 0.8,
    fontFamily: `${theme.typography.main}, Arial, Sans-serif`,
    transition: 'opacity 300ms',
    '&:hover': {
      background: theme.palette.primary.success,
      opacity: 1
    }
  },

  autocomplete: {
    cursor: 'pointer',
    '& .MuiAutocomplete-root': {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`
    }
  },

  icon: { cursor: 'pointer' }
}));

export const SearchField = styled.div`
  padding: 1rem;
  display: flex;
  width: 100%;
`;
