import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    columnGap: '1rem',
    width: '100%',
    minHeight: 446
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '1rem',
    columnGap: '1rem',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  creditCard: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: 380,
    height: 230,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1rem'
  },
  chip: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  progressYellow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
    marginTop: theme.spacing(1.5)
  },
  checkbox: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: 12,
    color: theme.palette.text.primary,
    '& span a': {
      color: theme.palette.text.primary
    },
    '& span svg': {
      color: theme.palette.text.primary
    }
  },
  checkboxred: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: 12,
    color: 'red',
    '& span a': {
      color: 'red'
    },
    '& span svg': {
      color: 'red'
    }
  },
  lowerContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  accordion_container: {
    width: '100%'
  },
  acceptContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    columnGap: '1rem'
  },
  cardWrapper: {
    margin: '0 auto',
    width: '100%',
    minWidth: '50%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(0.5)
    }
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '55px',
    rowGap: '0.5rem',
    columnGap: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'center'
    },
  },
  buttonDark: {
    borderRadius: '30px',
    alignSelf: 'center',
    height: '50px',
    color: 'whitesmoke !important',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none !important',
    transition: 'opacity 300ms',
    '&:hover': {
      cursor: 'pointer',
      opacity: '100%'
    }
  },
  card: {
    [theme.breakpoints.up('lg')]: {
      width: '50% !important'
    }
  }
}));
