import { TextField, useTheme } from '@material-ui/core';
import ModifierForm from 'components/PhotoAdminComponents/ModifierForm/ModifierForm';
import React from 'react';
import { useEffect } from 'react';

import * as Styles from './styles';

const TemplateDetails = ({
  name,
  setName,
  modifiers,
  setModifiers,
  buttonRef,
  selectedTemplate,
  setFormChanged,
  setDisabled
}) => {
  const theme = useTheme();
  const classes = Styles.useStyles(theme);

  const changeChecker = () => {
    if (selectedTemplate) {
      if (name !== selectedTemplate?.name) {
        setFormChanged(true);
      } else if (JSON.stringify(modifiers) !== JSON.stringify(selectedTemplate?.modifiers)) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    }
  };

  useEffect(() => {
    changeChecker();
  }, [name]);
  return (
    <Styles.Wrapper>
      <Styles.TitleWrapper>
        <TextField
          variant='outlined'
          className={classes.name}
          InputProps={{
            className: classes.name,
            style: {
              fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
              color: 'black'
            }
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Styles.TitleWrapper>
      <Styles.FormWrapper>
        <ModifierForm
          shopId={null} // TODO will we bind templates to shops?
          templateForm={true}
          modifiers={modifiers}
          changeChecker={changeChecker}
          setModifiers={setModifiers}
          buttonRef={buttonRef}
          inherit={false}
          setDisabled={setDisabled}
          belongsTo={null}
        />
      </Styles.FormWrapper>
    </Styles.Wrapper>
  );
};

export default TemplateDetails;
