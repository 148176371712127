import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    mainContainer: {
      width: '66%',
      padding: '2rem',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.down('lg')]: {
        width: '80%'
      }
    },
    title: {
      textAlign: 'center'
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      rowGap: '1.5rem',
      columnGap: '1.5rem',
      justifyContent: 'center',
      marginTop: '2rem',
      boxSizing: 'border-box',
      height: '575px'
    }
  };
});
