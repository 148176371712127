import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DocumentDownload, useStyles } from './styles';
import { useEffect } from 'react';
import { useDocumentApi } from './hooks/useDocumentApi';
import { Breadcrumbs, Container, CssBaseline, Zoom } from '@mui/material';

const Documents = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const userId = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser?.id);

  const { getDocuments, downloadDocument, loading, documents } = useDocumentApi();
  const buttonRef = useRef(null);

  useEffect(() => {
    getDocuments();
  }, [userId]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
        <Typography variant='body1'>Letölthető dokumentumok</Typography>
      </Breadcrumbs>
      <Zoom in={true} timeout={1000}>
        <Container maxWidth className={classes.container}>
          <Paper className={classes.paper}>
            <Grid container item xs={12} spacing={2}>
              {documents?.map((el) => {
                return (
                  <Grid item xs={12} style={{ display: 'flex', margin: '2rem' }}>
                    <Typography>{el.name}</Typography>
                    <DocumentDownload
                      onClick={() => {
                        downloadDocument(el.id, buttonRef, el.name);
                      }}>
                      {loading ? <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} /> : <FileDownloadIcon />}
                    </DocumentDownload>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Container>
      </Zoom>
    </Container>
  );
};

export default Documents;
