import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import background from 'assets/images/webshop_main_background.jpg';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-top: -75px;
  @media (min-width: 480px) {
    min-height: 100vh;
  }
  height: 100%;
  background-size: cover;
  background-image: url(${background});
  background-repeat: no-repeat;
  @media (min-width: 900px) {
    margin-top: 0;
  }
`;

export const BackhomeBtn = styled.a`
  color: ${({ theme }) => theme.palette.primary.backgroundPrimary};
  font-family: ${({ theme }) => theme.typography.main};
  opacity: 80%;
  text-decoration: none;
  position: absolute;
  left: 1rem;
  top: 1rem;
  &: hover {
    opacity: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(200, 200, 200);
  background-color: rgba(0, 0, 0, 0.2);

  z-index: 100;
  backdrop-filter: blur(2px);
  display: grid;
  place-items: center;
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 200px;
  height: 150px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 480px) {
    margin-bottom: 2rem;
  }
`;

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    backgroundColor: 'white',
    borderColor: 'transparent',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      borderRadius: '0 0 20px 20px'
    }
  },
  grid: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '1.5rem 2rem'
    }
  },

  stepLabel: {
    borderRadius: '22px'
  },
  label: {
    fontWeight: 400,
    fontSize: '1rem',
    margin: '1rem 0 0 0'
  },
  button: {
    textTransform: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '40px',
    width: '160px',
    border: 'none',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.backgroundSecondary,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.backgroundPrimary
    }
  }
}));
