import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AuthService from '../../../../../services/AuthService';

const AvatarIcon = ({ width, height }) => {
  return (
    <div>
      <Avatar style={{ width: width, height: height }}>{AuthService.getFirstName()[0].toUpperCase()}</Avatar>
    </div>
  );
};

export default AvatarIcon;
